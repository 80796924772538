/* global AWS */
// import AWS from 'aws-sdk'
// import * as AWS from '../aws.core.min.js'
export const awsRegion = 'us-west-2'
export const cognitoRegion = 'us-west-2'
export const cognitoUserPoolId = 'us-west-2_TQH7rOCRf'
export const cognitoIdentityPoolId = 'us-west-2:59baae77-c00d-4867-87ec-a787e61b561b'
export const cognitoClientId = 'tej1667vf16c06g5ak5h79405'
export const cognitoUserPoolDomain = 'tr-portal-dev.auth.us-west-2.amazoncognito.com'
export const websiteUrl = 'https://threat-removal.dev.dsdevops.com/'
export const apiUrl = 'https://threat-removal.dev.dsdevops.com/prod'
export const apiGatewayApiId = 'f096m2puq7' // the minimum needed for running locally in a meaningful way
export const s3BucketName = 'tr-portal-dev'
export const accountId = '431289091137'
export const mfaProviderName = 'dev-devportal'

export function getApiGatewayPublicApiUrl() {
    return apiUrl
}

export function getApiGatewayRealApiHost() {
    return `${apiGatewayApiId}.execute-api.${awsRegion}.amazonaws.com`
}

AWS.config.region = cognitoRegion
